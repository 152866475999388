import { endOfToday } from 'date-fns';
import moment from 'moment';
import { t } from './ReactSwitchLangWrapper';
import { formatCurrency, formatNumber } from './Helpers';
import { TIMEZONE_CODES } from './Constants';

/**
 * Combines multiple validator functions into a single validator function that
 * runs each validator until a failed validation is found
 * @param {function[]} validators
 * array of validators that takes in the value and returns an error message or undefined
 * @param {boolean} [isRequired] indicates if this a required or optional field; defaults to true
 * @param {string} [customMsg] optional custom message to return if validation fails
 * @returns {(value:string)=>string|undefined}
 * a function that takes in a value to validate and returns the result of validation;
 * the result of validation is either:
 * - an error message string if validation fails
 * - or undefined if validation passes
 */
function combineValidators(validators, isRequired = true, customMsg = null) {
  return (value) => {
    if (!value) return isRequired ? t('Validation_Required') : undefined;

    let result;
    // run validators until we find one that returns a truthy value
    validators.some((validator) => {
      result = validator(value); // result set to error message if validate fails, else undefined
      return result; // returning any truthy value will stop validation
    });
    return result ? ((customMsg && t(customMsg)) || result) : undefined;
  };
}

/* ******************************* Validator Generator Functions ******************************* */
const maxLength = (length) => (value) => (value.length > length ? t('Validation_Length_Max').replace('xMax', length) : undefined);
const minLength = (length) => (value) => (value.length < length ? t('Validation_Length_Min').replace('xMin', length) : undefined);
const lengthRange = (min, max) => (value) => minLength(min)(value) || maxLength(max)(value);
const exactLength = (length) => (value) => (value.length !== length ? t('Validation_Length_Exact').replace('xLength', length) : undefined);
const maxAmount = (max, omitDecimal = true) => (value) => (value > max ? t('Validation_Value_Max').replace('xMax', formatCurrency(max, omitDecimal)) : undefined);
const minAmount = (min, omitDecimal = true) => (value) => (value < min ? t('Validation_Value_Min').replace('xMin', formatCurrency(min, omitDecimal)) : undefined);
const maxNumber = (max) => (value) => (value > max ? t('Validation_Value_Max').replace('xMax', formatNumber(max)) : undefined);
const minNumber = (min) => (value) => (value < min ? t('Validation_Value_Min').replace('xMin', formatNumber(min)) : undefined);
const maxFileSize = (size) => () => (size > 2 ? t('Validation_Photo_OverLimit') : undefined);
// eslint-disable-next-line max-len
const acceptedFileTypes = (fileType, acceptedFiles, fileTypeError) => () => (!acceptedFiles.includes(fileType) ? fileTypeError : undefined);

const checkMod = () => (c) => {
  // If value of c (SIN/BN 000000000) less than 1, return false
  if (c < 1) { return t('Validation_Invalid_BusinessNumber'); }
  const cl = parseInt(c.substr(c.length - 1), 10);
  let cr = c.slice(0, -1);
  cr = cr.split('').reverse().join('');
  cr = cr.split('');
  let a = 2;
  const cm = [];
  for (let i = 0; i < cr.length; i += 1) {
    if (a % 2 === 0) {
      let tl = cr[i] * 2;
      if (tl > 9) { tl -= 9; }
      cm.push(tl);
    } else { cm.push(parseInt(cr[i], 10)); }
    a += 1;
  }
  let f = 0;
  for (let i = 0; i < cm.length; i += 1) { f += cm[i]; }
  f += cl;
  if (f % 10 === 0) { return undefined; } return t('Validation_Invalid_BusinessNumber');
};

/**
 * Generates a regex validator
 * @param {RegExp} regex regular expression to test against
 * @param {string} failMsg lang key for the text to return if the validation fails
 * @returns {(value:string)=>string|undefined} a validator function
 */
const regexValidator = (regex, failMsg) => (value) => (!regex.test(value) ? t(failMsg) : undefined);

/* **************************************** Validators **************************************** */
const passwordRegex = (value) => {
  const numberRegex = /.*[0-9].*/;
  const upperCaseRegex = /.*[A-Z].*/;
  const specialCharRegex = /.*[ !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~].*/;

  if (!specialCharRegex.test(value)) {
    return t('Registration_Validation_Password_SpecialChar');
  }
  if (!upperCaseRegex.test(value)) {
    return t('Registration_Validation_Password_UpperChar');
  }
  if (!numberRegex.test(value)) {
    return t('Registration_Validation_Password_Number');
  }
  return undefined;
};
const emailRegex = regexValidator(/^[\w!#$%&*+/=?`{|}~^-]+(?:\.[\w!#$%&*+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,32}$/, 'Validation_Invalid_Email');
const dateRegex = /(^(20|19|18)[0-9]{2}-[0-1][0-9]-[0-3][0-9]$)/;
const dateTimeRegex = /(^(20|19|18)[0-9]{2}-[0-1][0-9]-[0-3][0-9]) ([01][0-9]|2[0-3]):([0-5][0-9])$/;
const postalCodeRegex = regexValidator(/^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z] [0-9][ABCEGHJ-NPRSTV-Z][0-9]$/, 'Validation_Invalid_PostalCode');
const phoneNumberRegex = regexValidator(/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/, 'Validation_Invalid_PhoneNumber');
const validCharRegex = regexValidator(/^[a-zA-Z0-9&\-.,'âàéêèëîìïôòûùüçÂÀÉÊÈËÎÌÏÔÒÙÜÛÇ()]+(?: [a-zA-Z0-9&\-.,'âàéêèëîìïôòûùüçÂÀÉÊÈËÎÌÏÔÒÙÜÛÇ()]+)*$/, 'Validation_Invalid_ValidChar');
// const validGstHstNumRegex = regexValidator(/^\d{9}$/, 'Validation_Invalid_GSTHSTNumber');
const validUrlRegex = regexValidator(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/, 'Validation_Invalid_URL');
const interacInterfaceBusinessNameRegex = regexValidator(/^[a-zA-Z0-9àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ'\-.,][a-zA-Z0-9àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ '\-.,]+[a-zA-Z0-9àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ'\-.,]$/, 'Validation_Invalid_DisplayName');
const numberRegex = regexValidator(/^[0-9]+$/, 'Validation_Invalid_Number');
const obscuredBankAccountNumRegex = regexValidator(/^\*{5}\d{2}$/, 'Validation_Invalid_BankAccountNumber'); // 5 asterisks followed by 2 numbers
const percentRegex = regexValidator(/^(0|[1-9]\d*)(\.\d+)?$/, 'Validation_Invalid_Percent');

function isValidDate(value) {
  if (!value) return t('Validation_Required');
  if (!dateRegex.test(value)) return t('Validation_Type_Date');
  if (!moment(value).isValid()) return t('Validation_Type_Date');
  if (moment(value).isBefore(moment('1899-12-31'))) return t('Validation_Type_Date');
  let date = new Date(value);
  if (!(date instanceof Date && !Number.isNaN(date.valueOf()))) return t('Validation_Type_Date');
  date = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  if (date > endOfToday()) return t('Validation_Future_Date');
  return undefined;
}

function isValidDateTime(value) {
  if (!value) return t('Validation_Required');
  if (!dateTimeRegex.test(value)) return t('Validation_Type_DateTime');
  if (!moment(value).isValid()) return t('Validation_Type_Date');
  if (moment(value).isBefore(moment('1899-12-31'))) return t('Validation_Type_Date');
  const date = new Date(value);
  if (!(date instanceof Date && !Number.isNaN(date.valueOf()))) return t('Validation_Type_Date');

  return undefined;
}

function isValidTimezone(value) {
  if (!value) return t('Validation_Required');
  if (!Object.keys(TIMEZONE_CODES).includes(value)) return t('Validation_Required');

  return undefined;
}

// This function assumes start and end are both valid dateTimes
function isValidDateRange(start, end, maxRangeDays) {
  const startMoment = moment(start);
  const endMoment = moment(end);
  if (endMoment.isBefore(startMoment)) return t('Validation_Invalid_EndBeforeStart');
  if (endMoment.diff(startMoment, 'days', true) > maxRangeDays) return t('Validation_Invalid_DateRangeLimitExceeded', { days: maxRangeDays });

  return undefined;
}

export const validateRequired = (value) => (value ? undefined : t('Validation_Required'));
export const validateEmail = combineValidators([maxLength(100), emailRegex]);
export const validateOtherEmail = combineValidators([maxLength(64), emailRegex]);
export const validatePassword = combineValidators([lengthRange(10, 20), passwordRegex]);
export const validateVerificationCode = combineValidators([numberRegex, exactLength(6)], true, 'Validation_Invalid_VerificationCode');
export const validateDate = (value) => (isValidDate(value));
export const validateDateTime = (value) => (isValidDateTime(value));
export const validateDateRange = (
  start,
  end,
  maxRangeDays
) => isValidDateRange(start, end, maxRangeDays);
export const validateTimezone = (value) => (isValidTimezone(value));
export const validateBusinessName = combineValidators([maxLength(100), validCharRegex]);
export const validateInteracInterfaceBusinessName = combineValidators(
  [lengthRange(3, 80), interacInterfaceBusinessNameRegex]
);
export const validateFullName = combineValidators([maxLength(100), validCharRegex]);
export const validateOccupation = combineValidators([maxLength(50), validCharRegex]);
export const validateCorporationNum = combineValidators([numberRegex, lengthRange(1, 20)]);
export const validateGstHstNum = combineValidators([numberRegex, exactLength(9), checkMod()]);
export const validateUrl = combineValidators([validUrlRegex, maxLength(200)]);
export const validateRegulatedEntity = combineValidators([maxLength(100), validCharRegex]);

export const validateStreet = combineValidators([maxLength(60), validCharRegex]);
export const validateSuite = combineValidators([maxLength(20), validCharRegex], false);
export const validateCity = combineValidators([maxLength(70), validCharRegex]);
export const validateProvince = combineValidators([(value) => {
  switch (value) {
    case 'AB': case 'BC': case 'MB': case 'NB': case 'NL': case 'NT':
    case 'NS': case 'NU': case 'ON': case 'PE': case 'QC': case 'SK':
    case 'YT':
      return undefined;
    default:
      return true;
  }
}]);
export const validatePostalCode = combineValidators([maxLength(7), postalCodeRegex]);
export const validatePhoneNumber = combineValidators([maxLength(12), phoneNumberRegex]);
export const validateAmount = (value, max, min) => (
  combineValidators([maxAmount(max), minAmount(min)])(value)
);
export const validateRefundAmount = (value, max, min) => (
  combineValidators([maxAmount(max, false), minAmount(min, false)])(value)
);
export const validateNumberRange = (value, max, min) => (
  combineValidators([maxNumber(max), minNumber(min)])(value)
);
export const validatePercentRange = (value, max, min) => (
  combineValidators([maxNumber(max), minNumber(min), percentRegex])(value)
);
export const validateCheckbox = (value) => (value ? undefined : true);

// ensure base64 encoded file size is less than 2MB
export const validateUploadedFile = (value, size, fileType, acceptedFiles, fileTypeError) => (
  combineValidators([acceptedFileTypes(fileType, acceptedFiles, fileTypeError),
    maxFileSize(size)])(value)
);

export const validateBankBranch = combineValidators([exactLength(3), numberRegex]);
export const validateBankTransitNum = combineValidators([exactLength(5), numberRegex]);
export const validateBankAccountNum = combineValidators([lengthRange(7, 20), numberRegex]);
export const validateObscuredBankAccountNum = combineValidators([obscuredBankAccountNumRegex]);
